import './place-order.scss';

import {
  ajax
} from 'application/src/js/functions.js';
import {
  SpinnerOverlay
} from 'application/src/js/util.js';

customElements.define('place-order', class extends HTMLElement {
  constructor() {
    super();

    this._debugMode = true;
    this._translations = translations.webComponents.placeOrder;

    this._shadowRoot = this.attachShadow({
      mode: 'open'
    });

    const tpl = document.querySelector('#wc-tpl-place-order');
    if (!tpl || !tpl.content) return; // IE bugfix
    let clonedTpl = tpl.content.cloneNode(true);

    this._shadowRoot.appendChild(clonedTpl);
    this._shadowRoot.querySelector('link').addEventListener('load', e => this.setAttribute('loaded', ''));
    this._id = this.getAttribute('id');
    this._type = this.getAttribute('type');
    this._priceDisplayOption = parseInt(this.getAttribute('price-display-option'));
    this._measurement = this.getAttribute('measurement');
    this._packageWeight = parseFloat(this.getAttribute('package-weight'));
    this._quantityPerUnit = parseFloat(this.getAttribute('quantity-per-unit'));
    this._minOrderQuantity = parseFloat(this.getAttribute('min-order-quantity'));
    this._minUnits = Math.floor(this._minOrderQuantity / (this._quantityPerUnit * this._packageWeight));

    this._errorClass = 'error';
    this._hasChanged = false;

    this._priceDisplayOptionsMapper = {
      0: {
        unit: 'kg',
        baseUnitAmount: 1
      },
      1: {
        unit: 'kg',
        baseUnitAmount: 1
      },
      2: {
        unit: 't',
        baseUnitAmount: 1000
      },
      3: {
        unit: 'Stk',
        baseUnitAmount: 1
      },
      4: {
        unit: 'l',
        baseUnitAmount: 1
      }
    };
  }

  static get observedAttributes() {
    return ['loaded'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (!this._shadowRoot.querySelector('div')) return; // IE bugfix
    if (this._inner) this._inner.style.visibility = 'visible';
  }

  connectedCallback() {
    if (!this._shadowRoot.querySelector('div')) return; // IE bugfix
    this._inner = this._shadowRoot.querySelector('div');
    this._inputField = this._shadowRoot.querySelector('input');
    this._inputField.setAttribute('max', this._totalUnits);
    this._button = this._shadowRoot.querySelector('button');
    this._unitOut = this._shadowRoot.querySelector('label div');
    this._errorOut = this._shadowRoot.querySelector('.message');
    this._inputField.value = this._minUnits;
    this._disabled = false;

    this._loader = new SpinnerOverlay({
      element: this._shadowRoot.querySelector('.overlay')
    });

    this._calcAmount();
    this._addEvents();
    this._validate();
    if (this.hasAttribute('loaded')) this._inner.style.visibility = 'visible';
  }

  _addEvents() {
    this._inputField.addEventListener('input', e => {
      if (parseInt(e.target.value) < 0) e.target.value = 0;
      this._validate();
      this._hasChanged = true;
      this._calcAmount();
    });

    this._button.addEventListener('click', e => {
      e.preventDefault();

      if (!this._validate()) {
        // focus and move cursor to the end
        const val = this._inputField.value;
        this._inputField.value = '';
        this._inputField.focus();
        this._inputField.value = val;
        return;
      };

      this._disable();

      this._createContract()
        .then(response => {
          this._loader.show();
          location.href = `/smartContract/order/${response.data.smartcontract_offer_id}`;
        })
        .catch(e => {
          // request successful but with problems
          if (typeof e.data !== 'undefined') {

            // offer does not exist
            if (e.data === false) {
              this._errorOut.innerHTML = this._translations.offerNotExists;
              this.classList.add(this._errorClass);

            // amount not available
            } else {
              const totalUnitsChanged = this._totalUnits !== e.data.available_quantity;
              this._totalUnits = e.data.available_quantity;
              this._validate();

              if (totalUnitsChanged) {
                const evt = new CustomEvent('totalUnitsChanged', {});
                this.dispatchEvent(evt);
              }

              this._enable();
            }

          // request failed
          } else {
            if (this._debugMode && e) console.log(e);
            this._enable();
          }

          this._loader.hide();
        })
    });
  }

  _disable() {
    this._button.disabled = true;
    this._inputField.disabled = true;
  }

  _enable() {
    this._button.disabled = false;
    this._inputField.disabled = false;
  }

  _calcAmount() {
    const priceDisplayOption = this._priceDisplayOptionsMapper[this._priceDisplayOption];
    const priceUnit = priceDisplayOption.unit;

    const calculatedAmount = this._quantityPerUnit * this._packageWeight / priceDisplayOption.baseUnitAmount;
    const formattedAmount = new Intl.NumberFormat(languageIso, {
      maximumFractionDigits: 2
    }).format(calculatedAmount);

    this._unitOut.innerHTML = `
      <div>${formattedAmount} ${priceUnit}</div>
    `;
  }

  _createContract() {
    this._loader.show();

    return ajax('/smartContract/ajaxCreateContract', {
      id: this._id,
      type: this._type,
      quantity: parseInt(this._inputField.value)
    });
  }

  _validate() {
    const hasValue = !!this._inputField.value;
    const isNumeric = !isNaN(parseInt(this._inputField.value));
    const hasMinAmount = parseInt(this._inputField.value) >= this._minUnits;
    const volumeAvailable = parseInt(this._inputField.value) <= this._totalUnits;
    const isValid = hasValue && isNumeric && hasMinAmount && volumeAvailable;

    this._errorOut.innerHTML = '';

    if (!hasMinAmount) {
      this._errorOut.innerHTML = `
        ${this._translations.minOrderError}<br>
        <b>(${this._translations.min}: ${this._minUnits})</b>
      `;
    }

    if (!volumeAvailable) {
      this._errorOut.innerHTML = `
        ${this._translations.amountNotAvailableError}<br>
        <b>(${this._translations.available}: ${this._totalUnits})</b>
      `;
    }

    this.classList[!hasMinAmount || !volumeAvailable ? 'add' : 'remove'](this._errorClass);

    return isValid;
  }

  get _totalUnits() {
    return parseInt(this.getAttribute('total-units'));
  }

  set _totalUnits(units) {
    return this.setAttribute('total-units', units);
  }

  _remove() {
    this.parentElement.removeChild(this);
  }
});